import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useParams } from "react-router-dom";
import FankoTokenABI from "../ABI/pair.json";
import FankoFactoryABI from "../ABI/factory.json";
import BondingDetails from "../components/Bonding /BondingDetails";
import BondingCurveChart from "../components/Bonding /react-chartjs-2";
import BuySellInterface from "../components/Bonding /buysell";
import Notification from "../components/Bonding /buysell";
import TokenHolders from "../components/Bonding /TopHolder";
import Header from "../components/Bonding /Header";
import BuySellTransactions from "../components/Bonding /transaction";
import "./TokenDetail.css";
import { getFactoryAddress } from "../components/constant"; // Import factory address
import { getChainDataById } from "../chains"; // Import the function to get chain data
import { executeMulticall } from "../utils/callhelper"; // Multicall utility function

const MULTICALL_ADDRESS = "0x5952F3a8127F49c1342d2BAd16FE58f399a50DC0"; // Replace with your deployed Multicall2 address

const TokenDetail = () => {
  const { tokenAddress } = useParams();
  const [tokenDetails, setTokenDetails] = useState({});
  const [ethAmount, setEthAmount] = useState("");
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [creationBlock, setCreationBlock] = useState("");
  const [marketCap, setMarketCap] = useState(null);
  const [timeSinceCreation, setTimeSinceCreation] = useState("");
  const chainId = 146; // Fixed chain ID
  const { averageBlockTimeSeconds, priceUSD } = getChainDataById(chainId);

  useEffect(() => {
    const fetchTokenDetails = async () => {
      try {
        const provider = new ethers.providers.JsonRpcProvider("https://rpc.soniclabs.com");
        const factoryAddress = getFactoryAddress(chainId);
        const calls = [
          {
            target: factoryAddress,
            abi: FankoFactoryABI,
            function: "getTokenDetailsByAddress",
            params: [tokenAddress],
          },
          {
            target: tokenAddress,
            abi: FankoTokenABI,
            function: "name",
            params: [],
          },
          {
            target: tokenAddress,
            abi: FankoTokenABI,
            function: "symbol",
            params: [],
          },
          {
            target: tokenAddress,
            abi: FankoTokenABI,
            function: "totalSupply",
            params: [],
          },
          {
            target: factoryAddress,
            abi: FankoFactoryABI,
            function: "bondingCurves",
            params: [tokenAddress],
          },
        ];

        // Execute multicall
        const results = await executeMulticall(MULTICALL_ADDRESS, calls, provider);

        // Parse results
        const tokenDetail = results[0][0];
        const name = results[1][0];
        const symbol = results[2][0];
        const totalSupply = ethers.BigNumber.from(results[3][0]);
        const bondingCurve = results[4][0];

        setTokenDetails({
          name,
          symbol,
          totalSupply,
          bondingCurve,
          creator: tokenDetail[0],
          description: tokenDetail[2],
          image: tokenDetail[3] || "https://pbs.twimg.com/profile_images/1819068683093696512/mGq9bwZW_400x400.jpg",
          twitter: tokenDetail[4],
          telegram: tokenDetail[5],
          website: tokenDetail[6],
        });

        setCreationBlock(ethers.BigNumber.from(tokenDetail[1]).toNumber());

        // Get current block and calculate time since creation
        const currentBlock = await provider.getBlockNumber();
        const blockDifference = currentBlock - ethers.BigNumber.from(tokenDetail[1]).toNumber();
        const secondsAgo = blockDifference * averageBlockTimeSeconds;
        const hoursAgo = Math.floor(secondsAgo / 3600);
        setTimeSinceCreation(hoursAgo);

        // Calculate market cap
        const spotPriceInETH = bondingCurve?.spotPrice || ethers.constants.Zero;
        const formattedSpotPrice = ethers.utils.formatEther(spotPriceInETH);
        const spotPriceInUSD = formattedSpotPrice * priceUSD;
        const totalSupplyInTokens = ethers.utils.formatEther(totalSupply);
        const calculatedMarketCap = spotPriceInUSD * totalSupplyInTokens;

        setMarketCap(calculatedMarketCap);
      } catch (error) {
        console.error("Error fetching token details:", error);
      }
    };

    fetchTokenDetails();
  }, [tokenAddress]);

  const handleBuy = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const tokenContract = new ethers.Contract(tokenAddress, FankoTokenABI, signer);

      const tx = await tokenContract.buy({ value: ethers.utils.parseEther(ethAmount) });
      await tx.wait();
      setNotification({ message: "Buy successful!", type: "success" });
    } catch (error) {
      console.error("Error buying tokens:", error);
      setNotification({ message: "Buy failed!", type: "error" });
    }
  };

  const handleSell = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const tokenContract = new ethers.Contract(tokenAddress, FankoTokenABI, signer);

      const tx = await tokenContract.sell(ethers.utils.parseEther(ethAmount));
      await tx.wait();
      setNotification({ message: "Sell successful!", type: "success" });
    } catch (error) {
      console.error("Error selling tokens:", error);
      setNotification({ message: "Sell failed!", type: "error" });
    }
  };

  return (
    <div>
      <Header />

      <div className="container444">
        <div className="leftcolumn999">
          <BondingCurveChart
            tokenAddress={tokenAddress}
            marketCap={marketCap}
            tokenPrice={tokenDetails.bondingCurve?.spotPrice || 0}
            symbol={tokenDetails.symbol}
            creator={tokenDetails.creator}
            imageurl={tokenDetails.image}
            currentChainId={chainId}
          />
        </div>
        <div className="rightcolumn99">
          <BuySellInterface tokenAddress={tokenAddress} currentChainId={chainId} />
          <div className="box998">
            <div className="logoContainer123">
              <img src={tokenDetails.image} alt={`${tokenDetails.name} logo`} className="tokenLogo456" />
            </div>
            <div className="tokenInfo789">
              <h2 className="tokenName112">
                {tokenDetails.name}
                <span style={{ marginLeft: "10px", color: "#61dafb", fontSize: "8px" }}>
                  (created {timeSinceCreation} hours ago)
                </span>
              </h2>
              <p className="tokenSymbol324">{tokenDetails.symbol}</p>
            </div>
            <div className="socialLinks210">
              {tokenDetails.website && (
                <a href={tokenDetails.website} target="_blank" rel="noopener noreferrer">
                  🌐 Website
                </a>
              )}
              {tokenDetails.twitter && (
                <a href={tokenDetails.twitter} target="_blank" rel="noopener noreferrer">
                  𝕏 Twitter
                </a>
              )}
              {tokenDetails.telegram && (
                <a href={tokenDetails.telegram} target="_blank" rel="noopener noreferrer">
                  💬 Telegram
                </a>
              )}
            </div>
            <p className="tokenDescription897">{tokenDetails.description || "No description available."}</p>
          </div>
          <BondingDetails tokenAddress={tokenAddress} currentChainId={chainId} />
          <TokenHolders tokenAddress={tokenAddress} creationBlock={creationBlock} />
        </div>
      </div>

      {notification.message && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification({ message: "", type: "" })}
        />
      )}

      <BuySellTransactions tokenAddress={tokenAddress} creationBlock={creationBlock} currentChainId={chainId} />
    </div>
  );
};

export default TokenDetail;
